/* You can add global styles to this file, and also import other style files */
@import './assets/scss/custom-palette';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import './assets/scss/variables';
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "./assets/scss/fonts";
@import "./assets/scss/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";

/* === Template mixins === */
@import "./assets/scss/mixins/animation";
@import "./assets/scss/mixins/badges";
@import "./assets/scss/mixins/buttons";
@import "./assets/scss/mixins/misc";
@import "./assets/scss/mixins/color-functions";
@import "./assets/scss/mixins/cards";
@import "./assets/scss/mixins/blockqoute";
@import "./assets/scss/mixins/popovers";
@import "./assets/scss/mixins/tooltips";

/* === Core Styles === */
@import "./assets/scss/background";
@import "./assets/scss/typography";
@import "./assets/scss/reset";
@import "./assets/scss/responsive";
@import "./assets/scss/misc";
@import "./assets/scss/utilities";
@import "./assets/scss/demo";
@import "./assets/scss/spinner";

/* === Components === */
@import "./assets/scss/components/forms";
@import "./assets/scss/components/checkbox-radio";
@import "./assets/scss/components/icons";
@import "./assets/scss/components/tables";
@import "./assets/scss/components/accordions";
@import "./assets/scss/components/buttons";
@import "./assets/scss/components/breadcrumbs";
@import "./assets/scss/components/badges";
@import "./assets/scss/components/cards";
@import "./assets/scss/components/preview";
@import "./assets/scss/components/tooltips";
@import "./assets/scss/components/popovers";
@import "./assets/scss/components/lists";
@import "./assets/scss/components/bootstrap-progress";
@import "./assets/scss/components/bootstrap-alerts";
@import "./assets/scss/components/tabs";
@import "./assets/scss/components/dropdowns";
@import "./assets/scss/components/date-picker";
@import "./assets/scss/components/pagination";
@import "./assets/scss/components/portfolio";
@import "./assets/scss/components/todo-list";

@import "./assets/scss/components/landing-screens/auth";

/* === Layout === */
@import "./assets/scss/layout";

@mixin e($element) {
  &__#{$element} {
    @content;
  }
}

.btn-inverse-success {
  background-color: $bkt-primary;
  color: $white !important;
  border-radius: 5px;
}

.btn-inverse-success:disabled {
  background-color: #cee1e2;
}

.body-app {
  background: white;
}
//buttons
.btn-bkt {
  @include e(outline-dark) {
    color: #3e4b5b!important;
    border-color: #3e4b5b!important;
    padding: 0.875rem 0.5rem !important;
  }
  @include e(secondary) {
    background: #ebebeb;
    border-color: #ABBDC5;
  }
  @include e(facebook) {
    background: social-color("facebook");
  }
  @include e(google) {
    background: social-color("google");
  }
  @include e(instagram) {
    background: social-color("dribbble");
  }
  @include e(primary){
    background: #ebebeb;
    border: 2px solid $bkt-primary;
  }
}
.btn-danger:hover {
  background-color: #E4835F;
  border-color: #E4835F;
}

.btn-inverse-success:hover {
  background-color: #47aeb1;
  border-color: #98C7CE;
}

.btn-danger {
  border-radius: 5px;
  background-color: #dc5026;
}

.btn-dark {
  background-color: #424F5E;
}

.btn-dark:hover {
  background-color: #b6d0dd;
  border-color: #bfe6ff;
}

.btn-btk__outline-dark:hover {
  background: #FFFFFF;
  color: #6A8299;
  border-color: #6A8299;
}




.btn-bkt__secondary:hover {
  background: #FFFFFF;
  border-color: #6A8299;
}
$textColorBkt: #424f5e;

.header-style {
  color: $textColorBkt;
}
.text-secondary{
  color: #ABBDC5;
}

.page-title {
  font-size: 1.8rem;
  margin-left: 1.5rem;
  color: #ABBDC5;
}

.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04),
    0 2px 4px rgba(0, 0, 0, .25),
    0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}

.bkt-select .mat-select-arrow {
  border: none;
  width: 16px;
}

.bkt-select .mat-select-arrow-wrapper {
  background-image: url("assets/images/icons/down.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translateY(-25%) !important;
}


.bkt-select .mat-select-value {
  color: $bkt-secondary2;
}

.bkt-spinner .mat-progress-spinner circle, .mat-spinner circle {
  stroke: $bkt-primary !important;
}

btn-icon{
  width: 20px;
  height: 20px;
}
.icon-bkt {
  font-size: 2.5em;
  @include e(disabled) {
    color: $bkt-secondary2;
  }
  @include e(primary) {
    color: $bkt-primary;
  }
  @include e(danger) {
    color: #dc5026;
    fill: #dc5026;
  }
  @include e(wd48){
    width: 48px;
    height: 30px;
    line-height: 36px;
  }
}
.icon-wd-16{
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.cdk-overlay-pane{
  max-width: 90vw!important;
}
/* focus style */
.mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus, .mat-tab-group.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus, .mat-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus, .mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus{
  background: #ebebeb;
  border-radius: 25px;
}
/* ink bar style */
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
  background: $bkt-secondary2;
  height: 0px;
}
